import { render, staticRenderFns } from "./templateTbl.vue?vue&type=template&id=c22f1a8c&scoped=true&"
import script from "./templateTbl.vue?vue&type=script&lang=js&"
export * from "./templateTbl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c22f1a8c",
  null
  
)

export default component.exports