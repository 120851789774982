<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="flex justify-end">
            <router-link :to="{name: 'createEmailTemplate'}"
                         size="sm"
                         class="p-1 btn btn-outline-primary"
                         variant="outline-primary"
                         v-text="$t('Sidebar.memberCare.createTemplate')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container bg-white mt-1">
      <div class="scrol-area table-responsive">
        <table class="table b-table table-hover">
          <thead class="bg-white">
          <tr>
            <th
                v-for="(item, i) in fields"
                :key="i"
                class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
            >
              <div
                  class="flex justify-between gap-5 items-center w-full"
              >
                        <span class="text-xs text-gray-400">{{
                            $t(item.label)
                          }}</span>
              </div>
            </th>
            <th class="text-center whitespace-nowrap p-2 md:p-0 bg-white">
              <div class="flex justify-between gap-5 items-center w-full">
                <span class="text-xs text-gray-400">{{ $t('action') }}</span>
              </div>

            </th>
          </tr>
          </thead>
          <tbody>
          <template-tbl v-for="(item,i) in templateResults.results" :key="i" :template="item"
                        @onPreview="openTemplatePreview" @onDelete="onOpenDelete" @onDuplicate="onOpenDuplicateModal"/>
          </tbody>
        </table>
      </div>

    </div>
    <div class="container mt-1 flex justify-end">
      <paginate
          v-model="pagination.currentPage"
          :page-count="pagination.totalPages"
          :click-handler="firePagination"
          :prev-text="'<'"
          :next-text="'>'"
          :page-class="'page-item c-mr-1 border-unset'"
          prev-link-class="page-link c-mr-1 border-unset"
          next-link-class="page-link c-mr-1 border-unset"
          next-class="next-item"
          prev-class="prev-item"
          page-link-class="page-link c-mr-1 border-unset"
          active-class="active c-mr-1 border-unset"
          :container-class="'pagination  b-pagination'"
      >
      </paginate>
    </div>
    <Modal id="modal-preview-email" :hideFooter="true" :title="'Email Preview'" :size="`lg`">
      <b-card-text class="d-flex flex-column " style="gap: 1rem">
        <div v-html="html_content"></div>
      </b-card-text>
    </Modal>
    <Modal
        id="modal-delete-action"
        :hideFooter="true"
        :title="$t('emailSectionCard.deleteTemplateTitle')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t('emailSectionCard.deleteTemplate') }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-delete-action')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="onDelete"
            variant="primary"
            size="md"
            :disabled="deleting"
        >
          <span
              v-text="deleting ? $t('Message.loading') : $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <CustomNoHeaderModalVue v-if="openDuplicateModal" width="w-1/2 md:w-1/4" :title="''"
                            @handleClose="openModal = false"
                            :close="!true">
      <div class="flex flex-col" slot="header">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1">
          <div class="flex flex-col">
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">
              {{ $t('emailSectionCard.duplicateTemplate') }}</h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1">
          <div class="flex flex-col mt-2">
            <ValidationObserver ref="form">
              <div class="grid grid-cols-1 md:grid-cols-1 gap-1">
                <div class="">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        :name="'title'"
                        class="w-full"
                    >
                      <b-form-group :label="$t('emailSectionCard.duplicateTitle')" class="w-full">
                        <b-form-input v-model="duplicateForm.title"/>
                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="w-full flex justify-end mt-2">
                  <b-button
                      :disabled="duplicating"
                      size="md"
                      variant="success"
                      v-text="duplicating ? $t('Message.loading') : $t('emailSectionCard.duplicateTemplate')"
                      @click="onDuplicateTemplate"
                  />
                </div>
              </div>

            </ValidationObserver>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied :message="accessDeniedMessage"/>
    </Modal>
  </div>
</template>

<script>
import templateTbl from "@core/components/membercare/templateTbl";
import {mapGetters} from "vuex"
import Paginate from "vuejs-paginate";
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import {ValidationProvider, ValidationObserver} from "vee-validate";
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "emailTemplates",
  components: {
    templateTbl,
    paginate: Paginate, CustomNoHeaderModalVue, ValidationProvider, ValidationObserver,
    accessDenied
  },
  data: function () {
    return {
      deleting: false,
      processing: false,
      duplicating: false,
      openDuplicateModal: false,
      accessDeniedMessage: null,
      duplicateForm: {
        title: '',
        newsletter_template: ''
      },
      pagination: {
        currentPage: 1,
        totalPages: 1
      },
      templateResults: {results: []},
      fields: [
        {label: 'emailSectionCard.lastEditBy'},
        {label: 'emailSectionCard.lastEdit'},
        {label: 'emailSectionCard.templateTitle'},
        {label: 'emailSectionCard.status'},
      ],
      html_content: null,
      chosenData: {},
      limit: 20

    }
  },
  methods: {
    onDuplicateTemplate: function () {
      this.duplicating = true
      this.$useJwt
          .memberCare({method: 'post', URL: `newsletter/template/duplicate`}, this.duplicateForm)
          .then((res) => {
            this.duplicating = false
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
            Object.keys(this.duplicateForm).map(key => {
              this.duplicateForm[key] = ''
            })
            this.openDuplicateModal = false
            this.fetchTemplate()
          })
          .catch((err) => {
            this.duplicating = false
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    onOpenDuplicateModal: function (item) {
      this.duplicateForm.title = ''
      this.duplicateForm.newsletter_template = item.uuid
      this.openDuplicateModal = !this.openDuplicateModal
    },
    firePagination: function (number) {
      this.pagination.currentPage = number;
      this.$useJwt
          .memberCare({method: 'get', URL: `newsletter/template?limit=${this.limit}&page=${number}`})
          .then((res) => {
            this.templateResults = res.data.data;
            if (res.data.data.count > this.limit) {
              this.pagination.totalPages = Math.ceil(res.data.data.count / this.limit)
            } else {
              this.pagination.totalPages = 1
            }
          })
          .catch((err) => {
          });
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    fetchTemplate: function () {
      this.$useJwt
          .memberCare({method: 'get', URL: `newsletter/template?limit=${this.limit}&page=1`})
          .then((res) => {
            this.templateResults = res.data.data;
            if (res.data.data.count > this.limit) {
              this.pagination.totalPages = Math.ceil(res.data.data.count / this.limit)
            } else {
              this.pagination.totalPages = 1
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true);
              this.accessDeniedMessage = err.response.data.message;
              this.$bvModal.show("modal-access-denied");
            }
          });
    },
    openTemplatePreview: function (html) {
      this.html_content = html
      this.$bvModal.show("modal-preview-email");
    },
    onOpenDelete: function (item) {
      this.choosenData = item;
      this.$bvModal.show("modal-delete-action");
    },
    onDelete() {
      this.deleting = true
      this.$useJwt
          .memberCare({method: 'delete', URL: `newsletter/template/${this.choosenData.uuid}`})
          .then((res) => {
            this.deleting = false
            let itemIndex = this.templateResults.results.findIndex(item => item.uuid === this.choosenData.uuid)
            if (itemIndex > -1) {
              this.templateResults.results.splice(itemIndex, 1)
            }
            this.$bvModal.hide("modal-delete-action");
          })
          .catch((err) => {
            this.deleting = false
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    }
  },
  computed: {
    message: function () {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.fetchTemplate()
  }
}
</script>

<style scoped>

</style>