<template>
  <tr>
    <td>
      <span> {{ template.last_edited_by }}</span>
    </td>
    <td>
      <span>{{ parseDate(template.last_edited_at) }}</span>
    </td>
    <td>
      <span>{{ template.title }}</span>
    </td>
    <td>
      <span> <span class="badge bg-blue-800 pl-3 pr-3 pb-0.5 uppercase" style="padding-top: 7px;padding-bottom: 7px"
                   v-if="template.used_status === false">{{ $t('emailSectionCard.draft') }}</span>
      <span class="badge bg-green-500 pl-3 pr-3 pb-0.5 uppercase" style="padding-top: 7px;padding-bottom: 7px"
            v-else>{{ $t('emailSectionCard.sent') }}</span></span>
    </td>
    <td class="flex justify-around">
      <span v-on:click="onPreview">
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M19.8698 6.962C20.3438 7.582 20.3438 8.419 19.8698 9.038C18.3768 10.987 14.7948 15 10.6128 15C6.4308 15 2.8488 10.987 1.3558 9.038C1.12519 8.74113 1 8.37592 1 8C1 7.62408 1.12519 7.25887 1.3558 6.962C2.8488 5.013 6.4308 1 10.6128 1C14.7948 1 18.3768 5.013 19.8698 6.962V6.962Z"
    stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path
    d="M10.6128 11C12.2696 11 13.6128 9.65685 13.6128 8C13.6128 6.34315 12.2696 5 10.6128 5C8.95594 5 7.61279 6.34315 7.61279 8C7.61279 9.65685 8.95594 11 10.6128 11Z"
    stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </span>
      <span v-on:click="onEdit">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
<rect width="20" height="20" fill="url(#pattern0)"/>
<defs>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_2261_6508" transform="scale(0.0104167)"/>
</pattern>
<image id="image0_2261_6508" width="96" height="96"
       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAABtklEQVR4nO3aMU7DMBiG4Q9YGOAM3IOJQyCuwtgsSByJBQkheoQepBNjGaBShQKJ7d/+6uZ9JI+NnPdvmrSqBAAAAAA49CBpLWkraSPpSdKldUcLspK0G1nvkq6M+1qEQePx92st6dq2uxM3FZ8hVDQ3PkOoIDU+QwiUG58hBCiNXzSE8+Lt9+8z6Di3kl7ElZDlUTFXAR9HBRjCEYi6H+y/MU/+bHERfQade5N0Juku4Fg3+u77GnCsxYn6ONq03vixGn5W6mtKB7AN2Hv3DkO2HsJH+fb7Nhaw5RDui8+gY/+FazGEVfEZdGxOsOfEY6bcmIk/c9UYAvETV+QQiJ+5IoZA/MJVMgTiB62cp6PU15yUyPi5V8Ji1YjPEGaqGZ8hTGgRnyH8oWV8hvCLI371IfTyr4hB3mftqH9OdMn5zt+JL1nEdyG+EfGNiG9EfCPiGxHfiPhGxDcivhHxjYhvRHwj4hsR34j4RsQ3Ir4R8Y2Ib0R8I+IbEd+I+EbENyK+EfGNiG9GfDPimxHfjPhmxDcjvhnxzYhvRnwz4psR34z4ZsQ3I74Z8c2IDwAAAAAAAGT4AmWLJrfB4zyeAAAAAElFTkSuQmCC"/>
</defs>
</svg>


      </span>
      <span v-on:click="onDuplicateTemplate">
        <img src="./../../../assets/icon/copy.png" alt="duplicate template"/>
      </span>
      <span v-on:click="onDelete">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M13.1121 6.16406C13.1121 6.07383 13.0383 6 12.948 6L11.5945 6.00615L9.55605 8.43633L7.51963 6.0082L6.16406 6.00205C6.07383 6.00205 6 6.07383 6 6.16611C6 6.20508 6.01436 6.24199 6.03896 6.27275L8.70703 9.45146L6.03896 12.6281C6.01418 12.6582 6.00043 12.6958 6 12.7348C6 12.825 6.07383 12.8988 6.16406 12.8988L7.51963 12.8927L9.55605 10.4625L11.5925 12.8906L12.946 12.8968C13.0362 12.8968 13.1101 12.825 13.1101 12.7327C13.1101 12.6937 13.0957 12.6568 13.0711 12.6261L10.4071 9.44941L13.0752 6.2707C13.0998 6.24199 13.1121 6.20303 13.1121 6.16406Z"
    fill="black"/>
<path
    d="M9.1875 0C4.11387 0 0 4.11387 0 9.1875C0 14.2611 4.11387 18.375 9.1875 18.375C14.2611 18.375 18.375 14.2611 18.375 9.1875C18.375 4.11387 14.2611 0 9.1875 0ZM9.1875 16.8164C4.9752 16.8164 1.55859 13.3998 1.55859 9.1875C1.55859 4.9752 4.9752 1.55859 9.1875 1.55859C13.3998 1.55859 16.8164 4.9752 16.8164 9.1875C16.8164 13.3998 13.3998 16.8164 9.1875 16.8164Z"
    fill="black"/>
</svg>

      </span>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from "vuex"
import moment from "moment";

export default {
  name: "templateTbl",
  props: {
    template: {}
  },
  data: function () {
    return {
      duplicating: false
    }
  },
  computed: {
    message: function () {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    onPreview: function () {
      this.$emit('onPreview', this.template.html_content)
    },
    onEdit() {
      this.$router.push({
        name: 'editEmailTemplate',
        params: {uuid: this.template.uuid},
      })
    },
    onDelete() {
      this.$emit('onDelete', this.template)
    },
    onDuplicateTemplate: function () {
      this.$emit('onDuplicate', this.template)
    },
    parseDate: function (dt) {
      if (dt) {
        return moment(dt).format('YYYY-MM-DD')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>